/* Nav - transparent */
.transparent {
  background-color: #00b1dc;
  border: none;
  box-shadow: 1px 1px 10px;
}
.nav-row {
  width: 100%;
  justify-content: space-between;
  padding: 0 !important;
}
.navbar-nav {
  margin-right: -20px;
}
/* Nav Shrink & Animation */
#mylogo {
  margin-left: 20px;
}
.shrink {
  min-height: 45px;
}
.img-shrink {
  height: 45px !important;
}
#mynav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: all 0.6s ease;
}
/* Nav Children */
li.nav-item {
  font-family: 'black_ops_oneregular';
  font-size: 20px;
  font-weight: bold;
  line-height: 1px;
  float: right;
}
.nav-hov {
  font-family: 'black_ops_oneregular', Helvetica Neue, Helvetica, Arial,
    sans-serif;
  color: white;
}
.nav-hov:hover {
  color: black;
}
.nav-svg {
  width: 1.33rem;
  margin-right: 5px;
}
/* Show Hamburger Nav */
.nav-show {
  position: relative;
  top: 25%;
  width: 100%;
  transition: 0.5s;
}

/* Hamburger & Animation To X */
.hamburger {
  display: block;
  position: absolute;
  top: 45px;
  left: 18px;
  right: 18px;
  height: 7px;
  background: #000;
  transition: background 0 0.3s;
}
.hamburger::before,
.hamburger::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: #000;
  content: '';
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0;
}
.hamburger::before {
  top: -15px;
  transition-property: top, transform;
}
.hamburger::after {
  bottom: -15px;
  transition-property: bottom, transform;
}
/* Active State */
.my-toggler.open .hamburger {
  background: none;
}
.my-toggler.open .hamburger::before {
  top: 0;
  transform: rotate(45deg);
}
.my-toggler.open .hamburger::after {
  bottom: 0;
  transform: rotate(-45deg);
}
.my-togger.open .hamburger::before,
.my-toggler.open .hamburger::after {
  transition-delay: 0, 0.3s;
}
/* -------------------------------------- */
.my-toggler {
  outline: none;
  position: relative;
  margin: 0px -30px 0px 0px;
  padding: 0;
  width: 90px;
  height: 90px;
  font-size: 0;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
}
.ham-shrink {
  width: 80px !important;
}
.my-toggler:focus {
  outline: none;
}

.my-footer {
  background-color: black;
  color: white;
  font-family: 'Black Ops One';
  height: 150px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-top {
  text-align: center;
  font-size: 15px;
}
.footer-bottom {
  text-align: center;
  font-size: 15px;
}
.whiteHr {
  border-color: white;
  width: 100%;
}
.footer-hov {
  font-family: 'black_ops_oneregular', Helvetica Neue, Helvetica, Arial,
    sans-serif;
  color: white;
}
.footer-hov:hover {
  color: #00b1dc;
}
.footer-svg {
  width: 1.33rem;
  margin-right: 5px;
}
.footer-pad-control {
  padding: 0px !important;
  margin: 0px !important;
}

