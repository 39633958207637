/* Hamburger & Animation To X */
.hamburger {
  display: block;
  position: absolute;
  top: 45px;
  left: 18px;
  right: 18px;
  height: 7px;
  background: #000;
  -webkit-transition: background 0 0.3s;
  transition: background 0 0.3s;
}
.hamburger::before,
.hamburger::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: #000;
  content: '';
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0;
  transition-delay: 0.3s, 0;
}
.hamburger::before {
  top: -15px;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform;
}
.hamburger::after {
  bottom: -15px;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
}
/* Active State */
.my-toggler.open .hamburger {
  background: none;
}
.my-toggler.open .hamburger::before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.my-toggler.open .hamburger::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.my-togger.open .hamburger::before,
.my-toggler.open .hamburger::after {
  -webkit-transition-delay: 0, 0.3s;
  transition-delay: 0, 0.3s;
}
/* -------------------------------------- */
.my-toggler {
  outline: none;
  position: relative;
  margin: 0px -30px 0px 0px;
  padding: 0;
  width: 90px;
  height: 90px;
  font-size: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
}
.ham-shrink {
  width: 80px !important;
}
.my-toggler:focus {
  outline: none;
}
