/* Nav - transparent */
.transparent {
  background-color: #00b1dc;
  border: none;
  box-shadow: 1px 1px 10px;
}
.nav-row {
  width: 100%;
  justify-content: space-between;
  padding: 0 !important;
}
.navbar-nav {
  margin-right: -20px;
}
/* Nav Shrink & Animation */
#mylogo {
  margin-left: 20px;
}
.shrink {
  min-height: 45px;
}
.img-shrink {
  height: 45px !important;
}
#mynav {
  position: sticky;
  top: 0;
  z-index: 2;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
/* Nav Children */
li.nav-item {
  font-family: 'black_ops_oneregular';
  font-size: 20px;
  font-weight: bold;
  line-height: 1px;
  float: right;
}
.nav-hov {
  font-family: 'black_ops_oneregular', Helvetica Neue, Helvetica, Arial,
    sans-serif;
  color: white;
}
.nav-hov:hover {
  color: black;
}
.nav-svg {
  width: 1.33rem;
  margin-right: 5px;
}
/* Show Hamburger Nav */
.nav-show {
  position: relative;
  top: 25%;
  width: 100%;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
