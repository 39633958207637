.my-footer {
  background-color: black;
  color: white;
  font-family: 'Black Ops One';
  height: 150px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-top {
  text-align: center;
  font-size: 15px;
}
.footer-bottom {
  text-align: center;
  font-size: 15px;
}
.whiteHr {
  border-color: white;
  width: 100%;
}
.footer-hov {
  font-family: 'black_ops_oneregular', Helvetica Neue, Helvetica, Arial,
    sans-serif;
  color: white;
}
.footer-hov:hover {
  color: #00b1dc;
}
.footer-svg {
  width: 1.33rem;
  margin-right: 5px;
}
.footer-pad-control {
  padding: 0px !important;
  margin: 0px !important;
}
